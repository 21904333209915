@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

:root {
  --primary-color: #7286D3;
  --primary-accent-color: #65647C;
  --secondary-color: #f0f0f0;
  --accent-color: #E97777;
  --gradient-primary: #f0f0f0;
  --gradient-secondary: #f0f0f0;
}
/* *{
  outline: 3px solid limegreen !important;
} */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;400&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Azonix";
  src: local("Azonix"), url(fonts/Azonix.otf) format("opentype");
  font-display: swap;
}

@layer utilities {
  @variants group-hover {
    .shape-btn-h-32 {
      stroke-width: 4;
      stroke-dasharray: 125, 340;
      stroke-dashoffset: 265;
    }
  }
  .transform-letter-te7 {
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
  }
  .transform-behind-te7 {
    transform: rotateX(-90deg);
  }
  .transform-in-te7 {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .transform-out-te7 {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .transform-bgr1-s1 {
    transform: rotatez(45deg) translate(6px, 1px);
  }
  .transform-bgr1-s2 {
    transform: rotatez(-45deg);
  }
  .transform-bgr1-s3 {
    width: 50%;
    transform: translate(2px, -14px) rotatez(45deg);
  }
}

@layer utilities {
  .shadow-inset {
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  }
  @variants hover {
    .shadow-btn34-h7 {
      box-shadow: 0 0 5px #5dea8c, 0 0 25px #5dea8c, 0 0 50px #5dea8c,
        0 0 100px #5dea8c;
    }
  }
  .transform-after-the3 {
    transform: translate3d(101%, 0, 0);
  }
  @variants group-hover {
    .transform-h-after-the3 {
      transform: translate3d(0, 0, 0);
    }
  }
  .shadow-text5 {
    text-shadow: -15px 5px 20px #ced0d3;
  }
  @variants hover {
    .shadow-h-text5 {
      text-shadow: -16px 6px 15px #ced0d3;
    }
  }
}
@font-face {
  font-family: "Clip";
  src: url("../src/fonts/Clip.ttf") format("truetype");
}
@layer utilities {
  .text-shadow-te6 {
    text-shadow: 0 0 0.6rem #e2dcc8, 0 0 1.5rem #f1f1f1,
      -0.2rem 0.1rem 1rem #f1f1f1, 0.2rem 0.1rem 1rem #f1f1f1,
      0 -0.5rem 2rem #e2dcc8, 0 0.5rem 3rem #e2dcc8;
  }
}

@keyframes marquee-alt {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.moving-text {
  animation: marquee-alt 15s linear infinite;
}
.moving-text:hover {
  animation-play-state: paused;
}
@keyframes moveNext {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(20%) scale(1.2);
  }
  100% {
    transform: translateX(0%) scale(1);
  }
}

@keyframes moveNext2 {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(20%) scale(1.2);
  }
  100% {
    transform: translateX(0%) scale(1);
  }
}

.nextAnimation1 {
  animation: moveNext 0.3s ease-in-out;
}
.nextAnimation2 {
  animation: moveNext2 0.3s ease-in-out;
}

@keyframes movePrev1 {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(-20%) scale(1.2);
  }
  100% {
    transform: translateX(0%) scale(1);
  }
}

@keyframes movePrev2 {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(-20%) scale(1.2);
  }
  100% {
    transform: translateX(0%) scale(1);
  }
}

.prevAnimation1 {
  animation: movePrev1 0.3s ease-in-out;
}
.prevAnimation2 {
  animation: movePrev2 0.3s ease-in-out;
}
