.scrollbar::-webkit-scrollbar {
    width: 0.5em;
    height: 2em;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background-color: black;
    border-radius: 100px;
    
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #333333;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
  }