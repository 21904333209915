.Menu a {
    margin: auto;
    font-size: 1.5rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Menu a:active .slider,
.Menu a:hover .slider {
    animation-name: shift;
    animation-duration: 0.75s;
    animation-iteration-count: 1;
    transform: translateX(700%);
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.Menu a:hover{
    color: var(--primary-color); 
}


.Menu i{
    transform: scale(1.5);
    margin-top: 2% ;
    margin-bottom: 5%;
}

.Menu .line {

    width: 50%;
    border: 1px solid #041717;
    height: min-content;
    display: flex;
    align-items: center;
    margin-bottom: 2%;
}


.Menu .slider {
    width: 5%;
    border: 5px solid white;
    height: 0px;
    border-radius: 10px;
    position: absolute;
    background-color: white;
}

.Menu .social-media-links {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    color: var(--primary-color);
}


@keyframes shift{

    from{
        transform: translateX(0);
        border-color: white;
        background-color: white;
    }
    to{
        transform: translateX(700%);
        border-color: var(--primary-color);
        background-color: var(--primary-color);
    }
}

@keyframes shift-mobile{

    from{
        transform: translateX(0);
        border-color: white;
        background-color: white;
    }
    to{
        transform: translateX(500%);
        border-color: var(--primary-color);
        background-color: var(--primary-color);
    }
}

@media (max-width:480px) {
    .Menu .social-media-links{
        width: 100%;
    }

    .Menu .slider{
        width: 10%;
    }

    .Menu .line{
        width: 80%;
        margin-bottom: 5%;
    }

    .Menu a:hover .slider,.Menu a:active .slider{
        animation-name: shift-mobile;
        animation-duration: 0.75s;
        animation-iteration-count: 1;
        transform: translateX(500%);
    }

}