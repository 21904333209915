.spo_img{
    width: 100%;
    height: 5rem;
    filter: brightness(0) invert(1);
}

.logo_bg{
    background: linear-gradient(to right, #a6afc0 2px, transparent 2px) 0 0, linear-gradient(to bottom, #a6afc0 2px, transparent 2px) 0 0, linear-gradient(to left, #a6afc0 2px, transparent 2px) 100% 0, linear-gradient(to bottom, #a6afc0 2px, transparent 2px) 100% 0, linear-gradient(to left, #a6afc0 2px, transparent 2px) 100% 100%, linear-gradient(to top, #a6afc0 2px, transparent 2px) 100% 100%, linear-gradient(to right, #a6afc0 2px, transparent 2px) 0 100%, linear-gradient(to top, #a6afc0 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-color: transparent;
}

.logo_bg:hover{
    background-color: rgba(255, 255, 255, 0.078);
    background-size: 20% 20%;
    transition: background-size 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sponsors_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
}
.sponsors_container .sponsor_title{
    margin-bottom: 50px;
}
.sponsor_title p{
    max-width: 60em;
}
.sponsors .images{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0 50px;
}
.sponsors{
    margin-bottom: 50px;
    /* background-color: black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    border-radius: 10px;
}
.sponsors .img img{
    width: 100%;
    max-width:8em;
}
.sponsors .img{
    padding: 25px;
    background-color: rgb(179, 95, 95);
    height: 12em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
}
.sponsor_title h1{
    color: #7286D3;
}
.sponsor_title h1 span{
    color: white;
}
.sponsors h1{
    display: inline-block;
    color: #f0f0f0;
    text-transform: uppercase;
}
/* .sponsors_platinum h1{
    border-bottom: 2px solid cyan;
}
.sponsors_diamond h1{
    border-bottom: 2px solid pink;
}
.sponsors_gold h1{
    border-bottom: 2px solid gold;

}
.sponsors_silver h1{
    border-bottom: 2px solid silver;

} */

@media(max-width:510px){
    .sponsors .images{
        /* display: grid; */
        grid-template-columns: 1fr 1fr;
    }
}